<template>
  <div>
    <v-col cols="12">
      <v-form ref="form" v-model="valid" class="pa-3">
        <v-row>
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              color="orange"
              min="2"
              label="Фамилия"
              :value="value.Surname"
              @input="update('Surname', $event)"
              required
              :rules="lnRule"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              color="orange"
              min="2"
              label="Имя"
              :value="value.FirstName"
              @input="update('FirstName', $event)"
              required
              :rules="requiredRule"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              color="orange"
              min="2"
              label="Отчество"
              :value="value.MiddleName"
              @input="update('MiddleName', $event)"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <custom-date-picker
              :max="new Date()"
              format="dd.MM.yyyy"
              v-model="value.Birthday"
              mask="##.##.####"
              no-title
              outlined
              text="Дата рождения"
            >
            </custom-date-picker>
          </v-col>
          <v-col>
            <v-select
              outlined
              color="orange"
              label="Пол"
              :items="genders"
              :value="value.GenderCode"
              @input="update('GenderCode', $event)"
              :rules="requiredRule"
            ></v-select>
          </v-col>
          <v-col>
            <v-select
              outlined
              color="orange"
              label="Категория"
              :items="categories"
              :value="value.TypeCode"
              @input="update('TypeCode', $event)"
              :rules="requiredRule"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4">
            <v-select
              outlined
              color="orange"
              label="Тип документа"
              :items="docTypes"
              :value="value.TypeCodeDoc"
              @input="update('TypeCodeDoc', $event)"
              :rules="requiredRule"
            ></v-select>
          </v-col>
          <v-col cols="12" md="4">
            <v-text-field
              outlined
              color="orange"
              min="2"
              label="Номер документа"
              :value="value.DocNumber"
              @input="update('DocNumber', $event)"
              required
              :rules="requiredRule"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" md="4">
            <v-autocomplete
                outlined
                color="orange"
                label="Страна документа"
                :items="countriesList"
                :value="value.IssueCountryCode"
                @input="update('IssueCountryCode', $event)"
                :rules="requiredRule"
                auto-select-first
            >
            </v-autocomplete>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12" v-if="internationals.includes(value.TypeCodeDoc)">
            <custom-date-picker
              :min="new Date()"
              format="dd.MM.yyyy"
              v-model="value.ExpiryDate"
              mask="##.##.####"
              no-title
              outlined
              :disabled="!internationals.includes(value.TypeCodeDoc)"
              text="Срок окончания действия документа"
            >
            </custom-date-picker>
          </v-col>
        </v-row>
      </v-form>
    </v-col>
  </div>
</template>

<script>
import DateFormat from "@/mixins/DateFormat";
import Validators from "@/mixins/Validators";
import CustomDatePicker from "@/components/UI/CustomDatePicker.vue";

export default {
  components: { CustomDatePicker },
  name: "PassengerInfo",
  mixins: [Validators, DateFormat],
  data() {
    return {
      menu: [],
      valid: false,
      genders: [
        { text: "Мужской", value: "M" },
        { text: "Женский", value: "F" },
      ],
      categories: [
        { text: "Взрослый", value: "ADT" },
        { text: "Ребенок", value: "CNN" },
        { text: "Младенец(без места)", value: "INF" },
        // { text: "Военный", value: "MIL" },
      ],
      docTypes: [
        { text: "Паспорт РФ", value: "PS" },
        { text: "Свидетельство о рождении ", value: "SR" },
        { text: "Заграничный паспорт РФ", value: "PSP" },
        { text: "Военный билет", value: "VB" },
        { text: "Национальный паспорт", value: "NP" },
        { text: "Свидетельство на возвращение в страны СНГ", value: "CVV" },
        // { text: "Военный", value: "MIL" },
      ],
      internationals:[ "PSP", "NP"],
      maxDate: new Date().toISOString().substr(0, 10),
      minDate: new Date().toISOString().substr(0, 10),
    };
  },
  props: {
    value: {
      type: Object,
      require: true,
    },
    countriesList: {
      type: Array,
      default: [],
    },
  },
  methods: {
    update(key, value) {
      this.$emit("input", { ...this.value, [key]: value });
    },
    updateV() {
      this.$emit("updateValid", this.valid);
    },
  },
  watch: {
    valid: function () {
      this.updateV();
    },
  },
  computed: {
    // documentExpire() {
    //   return this.formatDateToDatepicker(this.value.Pspexpire);
    // },
    // birthdate() {
    //   return this.formatDateToDatepicker(this.value.Birthdate);
    // },
    // checkDoccode() {
    //   let a = this.docs.find((e) => e.value === this.docType).international;
    //   this.value.Pspexpire = a ? this.value.Pspexpire ?? "" : null;
    //   return a;
    // },
  },
};
</script>

<style></style>

