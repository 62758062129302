<template>
  <v-card class="pt-5 px-5 mb-12">
    <span class="text-h3 mt-10">Товары</span>
    <v-divider></v-divider>
    <v-row class="text-h5 mt-5">
      <v-col cols="12" md="6"> Билеты x {{ displayCount }}</v-col>
      <v-col cols="12" md="6" align="end">{{ displayPrice + "Руб" }} </v-col>
    </v-row>
    <v-divider></v-divider>
    <v-row class="mt-10">
      <v-col cols="12" md="4">
        <v-text-field
          v-if="!isSubagent"
          class="mb-0 custom-text-field"
          outlined
          color="orange"
          min="2"
          label="Сбор"
          v-model="markup"
          :v-mask="computedMask"
          @blur="handleInput"
          @keydown.enter="handleInput"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row class="text-h5 mt-0">
      <v-col cols="12" md="6">Итог</v-col>
      <v-col cols="12" md="6" align="end">{{ this.totalPrice + "Руб" }} </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      require: true,
    },
    displayCount: {
      type: Number,
      require: true,
    },
    displayPrice: {
      type: Number,
      require: true,
    },
    unavailableServices: Array,
    default: [],
  },
  model: {
    prop: "value",
    event: "change-markup",
  },
  data() {
    return {
      markup: "",
    };
  },
  methods: {
    handleInput() {
      // Если введено число до запятой, добавляем ",00"
      if (this.markup && !this.markup.includes(".")) {
        this.markup += ".00";
      }
    },
  },

  watch: {
    markup(val) {
      const numberValue = parseFloat(val);
      this.$emit("change-markup", numberValue.toFixed(2));
    },
  },
  computed: {
    computedMask() {
      // Dynamically adjust the mask based on the input length
      return this.markup.length > 4 ? '###,##' : '##,##';
    },
    totalPrice() {
      let parsed = Number(parseFloat(this.markup));
      console.log(parsed);

      if (!parsed) parsed = 0;

      return Number(this.displayPrice) + parsed;
    },

    isSubagent() {
      return this.$store.getters.user.role == this.$enums.Roles.SubAgent;
    },
  },
  mounted() {
    this.markup = this.value;
  },
};
</script>
<style>
.custom-text-field input[type="number"] {
  -moz-appearance: textfield;
}

.custom-text-field input::-webkit-outer-spin-button,
.custom-text-field input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
