<template>
  <v-card
    class="mx-auto"
    :outlined="isSelected"
    hover
    @click="$emit('select', brand)"
  >
    <v-card-title>
      <span
        class="text-h5"
        :class="isSelected ? 'orange--text text--lighten-2' : ''"
      >
        {{ brand.name }}
      </span>
    </v-card-title>

    <v-card-subtitle>
      <span class="text-h5 cyan--text text--accent-4">
        {{ brandPriceLabel }}
      </span>
    </v-card-subtitle>
    <v-card-text>
      <v-chip v-for="(baggage, i) in brand.baggages" :key="i" class="ma-1 px-2">
        {{ baggage }}
      </v-chip>
    </v-card-text>
    <v-card-text
      class="pa-1"
      v-if="brand.services != null"
      style="min-height: 150px"
    >
      <v-list-item
        v-for="(service, i) in included"
        :key="`paidSvc-${i}`"
        class="px-3"
      >
        <v-list-item-content>
          <v-list-item-subtitle>
            <v-icon color="success"> mdi-plus </v-icon>
            <span class="body-2">
              {{ service.description }}
            </span>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <v-btn icon @click="showAll">
        <v-icon>{{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        v-if="!isSelected"
        text
        color="orange"
        @click="$emit('select', brand)"
      >
        Выбрать
      </v-btn>
      <span v-else class="success--text pa-1"> Текущий </span>
    </v-card-actions>

    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text class="pa-1" v-if="brand.services != null">
          <v-list-item
            v-for="(service, i) in brand.services.filter((e) => e.access === 1)"
            :key="`paidSvc-${i}`"
            class="px-3"
          >
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-icon color="info"> mdi-currency-usd </v-icon>
                <span class="body-2">
                  {{ service.description }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="pa-1" v-if="brand.services != null">
          <v-list-item
            v-for="(service, i) in brand.services.filter((e) => e.access === 2)"
            :key="`paidSvc-${i}`"
            class="px-3"
          >
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-icon color="error"> mdi-close </v-icon>
                <span class="body-2">
                  {{ service.description }}
                </span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
export default {
  name: "BrandTariff",
  props: {
    brand: {
      type: Object,
      require: true,
    },
    brandPriceLabel: {
      type: String,
      require: true,
    },
    isSelected: {
      type: Boolean,
      require: true,
    },
  },
  data: () => ({
    show: false,
  }),
  methods: {
    showAll(e) {
      e.stopPropagation();
      this.show = !this.show;
    },
  },
  computed: {
    included() {
      const filtred = this.brand.services.filter(({ access }) => access === 0);
      return !this.show ? filtred.slice(0, 3) : filtred;
    },
  },
};
</script>

